import {
  Login,
  Products,
  Invoices,
  AccessControl,
  ProductsForm,
  AccessControlForm,
  RetailOrders,
  RetailOrdersForm,
  InvoicesForm,
  Factories,
  FactoriesForm,
  Profile,
  ProfileForm,
} from "../screens";

export const routes = [
  {
    path: "/",
    Component: Login,
    isPublic: true,
    exact: true,
  },
  {
    path: "/retailOrders",
    Component: RetailOrders,
    isPublic: false,
    exact: true,
  },
  {
    path: "/retailOrders/:action/:id",
    Component: RetailOrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/retailOrders/:action",
    Component: RetailOrdersForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products",
    Component: Products,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products/:action/:id",
    Component: ProductsForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/products/:action",
    Component: ProductsForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/invoices",
    Component: Invoices,
    isPublic: false,
    exact: true,
  },
  {
    path: "/invoices/:action/:id",
    Component: InvoicesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/invoices/:action",
    Component: InvoicesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/factories",
    Component: Factories,
    isPublic: false,
    exact: true,
  },
  {
    path: "/factories/:action/:id",
    Component: FactoriesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/factories/:action",
    Component: FactoriesForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/profile",
    Component: Profile,
    isPublic: false,
    exact: true,
  },
  {
    path: "/profile/:action/:id",
    Component: ProfileForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/profile/:action",
    Component: ProfileForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins",
    Component: AccessControl,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action/:id",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
  {
    path: "/all-admins/:action",
    Component: AccessControlForm,
    isPublic: false,
    exact: true,
  },
];

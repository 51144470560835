import React, { useEffect } from "react";
import ReactTable, { useTable, usePagination, useRowSelect } from "react-table";
import { BiChevronRight, BiChevronLeft } from "react-icons/bi";
import { isNil } from "lodash";

const Table = (props) => {
  const columns = React.useMemo(() => [...props.columns], []);
  return (
    <div className="px-2 my-4 overflow-x-auto scroll-smooth">
      <RenderTable {...props} />
    </div>
  );
};

function RenderTable({
  columns,
  data,
  darkTheme,
  paginationData,
  onChangePageNumber,
  onChangePageSize,
  totalPages,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: { pageSize: 100 },
      },
      usePagination,
      useRowSelect
    );

  let { page: selectedPage, perPage: selectedRows } = paginationData;

  return (
    <>
      <table
        {...getTableProps()}
        className="min-w-full text-left border-collapse"
      >
        <thead className="text-sm">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={`border-r-2 py-0 px-2 cursor-pointer ${
                    !darkTheme ? "border-smoke" : "border-white"
                  }`}
                  style={{
                    minWidth: `${column.minWidth}px`,
                    maxWidth: `${column.maxWidth}px`,
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="text-sm">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="hover:bg-grey hover:text-black"
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-4 px-2 cursor-pointer"
                      style={{
                        minWidth: `${cell.column.minWidth}px`,
                        maxWidth: `${cell.column.maxWidth}px`,
                      }}
                    >
                      {!isNil(cell.column.renderCell)
                        ? cell.column.renderCell(
                            cell.column.id === "actions"
                              ? cell.row.original
                              : cell.value
                          )
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* pagination */}
      <div className="flex justify-end text-sm mt-6 items-center">
        <span className="mx-5">
          Page{" "}
          <strong>
            {selectedPage} of {totalPages}
          </strong>{" "}
        </span>
        <span className="flex items-center">
          <button
            onClick={() => {
              onChangePageNumber(selectedPage - 1);
            }}
            disabled={selectedPage <= 1}
            className={`${
              !(selectedPage <= 1) && "hover:bg-grey hover:text-black"
            } p-2 mx-1 rounded-full`}
          >
            <BiChevronLeft />
          </button>
          <button
            onClick={() => {
              onChangePageNumber(selectedPage + 1);
            }}
            disabled={selectedPage >= totalPages}
            className={`${
              !(selectedPage >= totalPages) && "hover:bg-grey hover:text-black"
            } p-2 mx-1 rounded-full`}
          >
            <BiChevronRight />
          </button>
          <span className="ml-3">Rows per page </span>
          <select
            value={selectedRows}
            name="selectedRows"
            onChange={(e) => {
              onChangePageSize(e.target.value);
            }}
            className={`${
              !darkTheme ? "bg-light" : "bg-dark"
            } transition duration-500`}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </span>
      </div>
    </>
  );
}

export default Table;

import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createStoreService = async (data) => {
  try {
    let URL = apiURL + "retailer/createStore";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateStoreAddressService = async (data) => {
  try {
    let URL = apiURL + "retailer/updateAddress/" + data.id;
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getStoreService = async (data) => {
  try {
    let URL = apiURL + "retailer/getStore/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllStoresService = async () => {
  try {
    let URL = apiURL + "retailer/getAllStores";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GET_ONE_PRODUCT } from "../../store/actions/products";

export default function ProductsForm() {
  const { action, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    if (action === "view") setReadOnly(true);
    else setReadOnly(false);
  }, [action]);

  useEffect(() => {
    if (id && id !== "default") dispatch(GET_ONE_PRODUCT.REQUEST({ id }));
  }, [id]);

  return <div className="capitalize">{action} ProductsForm</div>;
}

import ActionTypes from "../actions/types";

const categoryInitialState = {
  allCategories: [],
  selectedCategory: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const categoryReducer = (state = categoryInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Categories.SET_CATEGORIES_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.Categories.CREATE_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Categories.CREATE_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Categories.CREATE_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Categories.UPDATE_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Categories.UPDATE_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Categories.UPDATE_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: action.payload.message,
      };
    case ActionTypes.Categories.GET_ONE_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Categories.GET_ONE_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedCategory: action.payload,
        error: null,
      };
    case ActionTypes.Categories.GET_ONE_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Categories.GET_ALL_CATEGORIES.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Categories.GET_ALL_CATEGORIES.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        allCategories: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.Categories.GET_ALL_CATEGORIES.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };
    case ActionTypes.Categories.DELETE_CATEGORY.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Categories.DELETE_CATEGORY.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.Categories.DELETE_CATEGORY.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default categoryReducer;

import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createAdminService = async (data) => {
  try {
    let URL = apiURL + "admin/createAdmin";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const verifyAdminService = async (data) => {
  try {
    let URL = apiURL + "admin/verify/" + data.token;
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getOneAdminService = async (data) => {
  try {
    let URL = apiURL + "admin/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllAdminsService = async () => {
  try {
    let URL = apiURL + "admin/getAll";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateProfileByAdminService = async (data) => {
  try {
    let URL = apiURL + "admin/profileData/" + data.id;
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const blockAdminService = async (data) => {
  try {
    let URL = apiURL + "admin/blockAdmin/" + data.id;
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const getAllCustomerOrdersService = async (data) => {
  try {
    let URL = apiURL + "customerOrder/getAll";
    console.log("API URL endpoint = ", URL);
    let config = {
      params: {
        page: data.page,
        perPage: data.perPage,
        status: data.status,
      },
    };
    let res = await authRequest(URL, "GET", {}, config);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const createCustomerOrdersService = async (data) => {
  try {
    let URL = apiURL + "customerOrder/create";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};
export const fetchCustomerOrdersService = async (data) => {
  try {
    let URL = apiURL + "customerOrder/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

import { call, takeLatest, put } from "redux-saga/effects";
import ActionTypes from "../actions/types";
import {
  createCategoryService,
  updateCategoryService,
  getOneCategoryService,
  getAllCategoriesService,
  deleteCategoryService,
} from "../../services/category";
import { toast } from "react-toastify";
import {
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  GET_ONE_CATEGORY,
  GET_ALL_CATEGORIES,
  DELETE_CATEGORY,
} from "../actions";

export function* createCategorySaga(action) {
  try {
    let res = yield call(createCategoryService, action.payload);
    if (res.success) {
      yield put(CREATE_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(CREATE_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      CREATE_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* updateCategorySaga(action) {
  try {
    let res = yield call(updateCategoryService, action.payload);
    if (res.success) {
      yield put(UPDATE_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(UPDATE_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      UPDATE_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getOneCategorySaga(action) {
  try {
    let res = yield call(getOneCategoryService, action.payload);
    if (res.success) {
      yield put(GET_ONE_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(GET_ONE_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ONE_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* getAllCategoriesSaga(action) {
  try {
    let res = yield call(getAllCategoriesService, action.payload);
    if (res.success) {
      yield put(GET_ALL_CATEGORIES.SUCCESS(res.data));
    } else {
      yield put(GET_ALL_CATEGORIES.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      GET_ALL_CATEGORIES.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

export function* deleteCategorySaga(action) {
  try {
    let res = yield call(deleteCategoryService, action.payload);
    if (res.success) {
      yield put(DELETE_CATEGORY.SUCCESS(res.data));
    } else {
      yield put(DELETE_CATEGORY.FAILURE(res));
      toast.error(res.message);
    }
  } catch (e) {
    yield put(
      DELETE_CATEGORY.FAILURE({
        success: false,
        message: e.message,
      })
    );
    toast.error("Unexpected Error Occurred.");
  }
}

const categorySaga = () => [
  takeLatest(
    ActionTypes.Categories.CREATE_CATEGORY.REQUEST,
    createCategorySaga
  ),
  takeLatest(
    ActionTypes.Categories.UPDATE_CATEGORY.REQUEST,
    updateCategorySaga
  ),
  takeLatest(
    ActionTypes.Categories.GET_ONE_CATEGORY.REQUEST,
    getOneCategorySaga
  ),
  takeLatest(
    ActionTypes.Categories.GET_ALL_CATEGORIES.REQUEST,
    getAllCategoriesSaga
  ),
  takeLatest(
    ActionTypes.Categories.DELETE_CATEGORY.REQUEST,
    deleteCategorySaga
  ),
];
export default categorySaga();

import { all } from "redux-saga/effects";
import auth from "./auth";
import customerOrders from "./customerOrders";
import products from "./products";
import brands from "./brands";
import category from "./category";
import giftCards from "./giftCards";
import retailerStores from "./retailerStores";
import admin from "./admin";

const sagas = [
  ...auth,
  ...customerOrders,
  ...products,
  ...brands,
  ...category,
  ...giftCards,
  ...retailerStores,
  ...admin,
];

export function* rootSaga() {
  yield all(sagas);
}

import { actionCreator } from "../../utils";
import ActionTypes from "./types";

export const GET_ALL_CUSTOMER_ORDERS = actionCreator(
  ActionTypes.CustomerOrder.GET_ALL_CUSTOMER_ORDERS
);

export const CREATE_CUSTOMER_ORDER = actionCreator(
  ActionTypes.CustomerOrder.CREATE_CUSTOMER_ORDER
);

export const FETCH_CUSTOMER_ORDER = actionCreator(
  ActionTypes.CustomerOrder.FETCH_CUSTOMER_ORDER
);

export const setOrderPagination = (payload) => ({
  type: ActionTypes.CustomerOrder.SET_ORDERS_PAGINATION,
  payload,
});

import { apiURL } from "../constants";
import { authRequest } from "../utils/requestService";

export const createCategoryService = async (data) => {
  try {
    let URL = apiURL + "category/create";
    let res = await authRequest(URL, "POST", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const updateCategoryService = async (data) => {
  try {
    let URL = apiURL + "category/update";
    let res = await authRequest(URL, "PUT", {}, data.value);
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getOneCategoryService = async (data) => {
  try {
    let URL = apiURL + "category/getOne/" + data.id;
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const getAllCategoriesService = async () => {
  try {
    let URL = apiURL + "category/getAll";
    let res = await authRequest(URL, "GET", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

export const deleteCategoryService = async (data) => {
  try {
    let URL = apiURL + "category/delete/" + data.id;
    let res = await authRequest(URL, "Delete", {}, {});
    return res;
  } catch (e) {
    return {
      success: false,
      data: {},
      message: e.message,
    };
  }
};

import ActionTypes from "../actions/types";

const authInitialState = {
  userData: {},
  authToken: "",
  loading: false,
  isLoggedIn: false,
  error: null,
  darkTheme: false,
};

const authReducer = (state = authInitialState, action) => {
  switch (action.type) {
    case ActionTypes.Auth.TOGGLE_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme,
      };
    case ActionTypes.Auth.LOGIN_WITH_EMAIL.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.Auth.LOGIN_WITH_EMAIL.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        userData: action.payload.userData,
        authToken: action.payload.token,
        error: null,
      };
    case ActionTypes.Auth.LOGIN_WITH_EMAIL.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
        userData: {},
      };

    default:
      return state;
  }
};

export default authReducer;

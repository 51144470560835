const sidebarContent = [
  {
    label: "Retail Orders",
    hasChildren: false,
    navigationRoute: "/retailOrders",
  },
  {
    label: "Products",
    hasChildren: false,
    navigationRoute: "/products",
  },
  {
    label: "Accounting",
    hasChildren: true,
    children: [
      {
        label: "Invoices",
        hasChildren: false,
        navigationRoute: "/invoices",
      },
    ],
  },
  {
    label: "Settings",
    hasChildren: true,
    children: [
      {
        label: "Access Control",
        hasChildren: false,
        navigationRoute: "/all-admins",
      },
      {
        label: "Factories",
        hasChildren: false,
        navigationRoute: "/factories",
      },
      {
        label: "Profile",
        hasChildren: false,
        navigationRoute: "/profile",
      },
    ],
  },
];
export default sidebarContent;

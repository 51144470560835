import ActionTypes from "../actions/types";

const customerOrdersInitialState = {
  allCustomerOrders: [],
  selectedCustomerOrder: {},
  loading: false,
  error: null,
  paginationData: {
    page: 1,
    perPage: 5,
  },
  pages: 1,
};

const customerOrdersReducer = (state = customerOrdersInitialState, action) => {
  switch (action.type) {
    case ActionTypes.CustomerOrder.SET_ORDERS_PAGINATION:
      return {
        ...state,
        paginationData: action.payload,
      };
    case ActionTypes.CustomerOrder.GET_ALL_CUSTOMER_ORDERS.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.CustomerOrder.GET_ALL_CUSTOMER_ORDERS.SUCCESS:
      return {
        ...state,
        loading: false,
        allCustomerOrders: action.payload.result,
        pages: action.payload.pages,
        error: null,
      };
    case ActionTypes.CustomerOrder.GET_ALL_CUSTOMER_ORDERS.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        pages: 1,
        allCustomerOrders: [],
      };

    case ActionTypes.CustomerOrder.CREATE_CUSTOMER_ORDER.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.CustomerOrder.CREATE_CUSTOMER_ORDER.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        error: null,
      };
    case ActionTypes.CustomerOrder.CREATE_CUSTOMER_ORDER.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    case ActionTypes.CustomerOrder.FETCH_CUSTOMER_ORDER.REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
      };
    case ActionTypes.CustomerOrder.FETCH_CUSTOMER_ORDER.SUCCESS:
      return {
        ...state,
        loading: false,
        isLoggedIn: true,
        selectedCustomerOrder: action.payload,
        error: null,
      };
    case ActionTypes.CustomerOrder.FETCH_CUSTOMER_ORDER.FAILURE:
      return {
        ...state,
        loading: false,
        isLoggedIn: false,
        error: action.payload.message,
      };

    default:
      return state;
  }
};

export default customerOrdersReducer;
